import React, { PropsWithChildren, ReactElement } from 'react';
import { TextStyle } from '../theme';
import { Container, Level, Message } from './styledComponents';
export { Level } from './styledComponents';
export interface Props extends PropsWithChildren<{}> {
  level: Level;
  textSize?: TextStyle;
}
const Feedback = ({
  level,
  children,
  textSize = TextStyle.BODY_3
}: Props): ReactElement => <Container level={level} data-sentry-element="Container" data-sentry-component="Feedback" data-sentry-source-file="index.tsx">
    <Message styleKey={textSize} level={level} data-sentry-element="Message" data-sentry-source-file="index.tsx">
      {children}
    </Message>
  </Container>;
export default Feedback;