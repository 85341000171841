import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import Text from '@legacy-components/Text';
import { Color } from '@legacy-components/theme';
import { colorFromTheme } from '@legacy-components/theme/helper';
import { deviceMedia } from '../../../core/helper/styles';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  width: 50%;
  height: 100%;
`;

export const Title = styled(Text)`
  margin-bottom: 2.5em;

  ${deviceMedia.phoneL`
    margin-bottom: 1em;
  `}
`;

export const LeftContainer = styled(Column)`
  background-image: url('/landing.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  background-color: ${colorFromTheme(Color.PRIMARY)};
  ${deviceMedia.laptop`
    display: none;
  `};
`;

export const RightContainer = styled(Column)`
  overflow-y: auto;
  ${deviceMedia.laptop`
    width: 100%;    
  `}
`;

interface RightContainerWrapperProps {
  isContentPositionedHigher?: boolean;
}

export const RightContainerWrapper = styled.div<RightContainerWrapperProps>`
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  ${({
    isContentPositionedHigher,
  }: RightContainerWrapperProps): FlattenSimpleInterpolation => css`
    top: ${isContentPositionedHigher ? '45%' : '50%;'};
  `}
  transform: translateY(-50%);
  ${deviceMedia.laptopL`	
    width: 75%	
  `}
  ${deviceMedia.phoneL`
    padding-top: 1.5em;	    
    top: 0;
    transform: none;	
    width: 90%;	
  `}
`;

export const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  margin: 1.5em 2.5em 0 1.5em;

  ${deviceMedia.phoneL`
    margin: auto;
    margin-top: 1.5em;
    width: 90%;
  `}
`;

export const ButtonContainer = styled.div`
  min-height: 2.5em;
`;

export const LogoContainer = styled.div`
  width: 8em;
  box-sizing: border-box;
  padding-bottom: 2em;

  ${deviceMedia.phoneL`
    width: 6em;
    padding-bottom: 1em
  `}
`;

export const StyledLogo = styled.img`
  width: 100%;
`;
