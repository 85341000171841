import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { TextStyle } from '@legacy-components/theme';
import { ButtonContainer, Container, LeftContainer, LogoContainer, NavBar, RightContainer, RightContainerWrapper, StyledLogo, Title } from './styledComponents';
interface Props {
  leftButton?: ReactNode;
  rightButton?: ReactNode;
  title?: ReactNode;
  withLogo?: boolean;
  isContentPositionedHigher?: boolean;
}
const Layout = ({
  children,
  leftButton,
  rightButton,
  title,
  withLogo = false,
  isContentPositionedHigher = false
}: PropsWithChildren<Props>): ReactElement => <Container data-sentry-element="Container" data-sentry-component="Layout" data-sentry-source-file="index.tsx">
    <LeftContainer data-sentry-element="LeftContainer" data-sentry-source-file="index.tsx" />
    <RightContainer data-sentry-element="RightContainer" data-sentry-source-file="index.tsx">
      <NavBar data-sentry-element="NavBar" data-sentry-source-file="index.tsx">
        <ButtonContainer data-sentry-element="ButtonContainer" data-sentry-source-file="index.tsx">{leftButton}</ButtonContainer>
        <ButtonContainer data-sentry-element="ButtonContainer" data-sentry-source-file="index.tsx">{rightButton}</ButtonContainer>
      </NavBar>
      <RightContainerWrapper isContentPositionedHigher={isContentPositionedHigher} data-sentry-element="RightContainerWrapper" data-sentry-source-file="index.tsx">
        {withLogo && <LogoContainer>
            <StyledLogo src="/logo.svg" />
          </LogoContainer>}
        {title && <Title styleKey={TextStyle.HEADER_2}>{title}</Title>}
        {children}
      </RightContainerWrapper>
    </RightContainer>
  </Container>;
export default Layout;