/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { CLIENT_INTERCOM_QUERY } from '@app-lib/account/query';
import { User } from '@app-lib/account/typings';
import { SignInFormValues } from '@app-lib/authentication/typings';

import * as browserHelpers from '../../../core/helper/browser';
import * as sentryHelper from '../../../core/helper/sentry';
import { Nullable } from '../../../core/typings';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { update } from '@intercom/messenger-js-sdk';

const INVALID_COOKIE_ERROR_MESSAGE =
  'Network error: Response not successful: Received status code 500';
const userIdCookieName = 'user-id';

export function handleInvalidUserIdCookie(error: Error): void {
  if (error.message === INVALID_COOKIE_ERROR_MESSAGE) {
    browserHelpers.deleteCookie(userIdCookieName);
  }
}

export interface HandleSignInParams extends SignInFormValues {
  signIn: (email: string, password: string) => Promise<Nullable<User>>;
}

export async function registerIntercomUser(client: ApolloClient<NormalizedCacheObject>): Promise<void> {
  const {
    data: { intercom: intercomUserData },
  } = await client.query({
    query: CLIENT_INTERCOM_QUERY,
  });
  update({
    created_at: new Date().getTime(),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    user_id: intercomUserData.user_id.toString(),
    email: intercomUserData.email,
    user_hash: intercomUserData.web_user_hash,
    name: intercomUserData.fullname,
  });
}

export async function handleSignIn({
  signIn,
  email,
  password,
}: HandleSignInParams): Promise<User | null> {
  try {
    const user: Nullable<User> = await signIn(email, password);
    if (user === null) return null;
    return user;
  } catch (err) {
    handleInvalidUserIdCookie(err as Error);
    sentryHelper.captureException(err as Error, { email });
  }
  return null;
}
